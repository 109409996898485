import { ListHeaderItem, RequestData } from '@/components/common/list';

type SearchKey = Array<{
    name: string;
    label: string;
}>;

type ProjectAccessData = {
    [key in ProjectType]: {
        headers: Array<ListHeaderItem>;
        InAccessRequestData: RequestData;
        OutAccessRequestData: RequestData;
        searchKeyList: SearchKey;
    }
};

const getProjectAccessData = (id: string) => {
    const communityHeaders: Array<ListHeaderItem> = [{
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName,
        type: 'customize'
    }, {
        name: 'UnitName',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'RoomName',
        label: WordList.RDeviceSearchLabelRoomName
    }];

    const officeHeaders: Array<ListHeaderItem> = [{
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName,
        type: 'customize'
    }, {
        name: 'DepartmentName',
        label: WordList.ProperAllTextDepartment
    }];

    const communityInAccessRequestData: RequestData = {
        url: 'v3/web/community/access/getPeopleInAccess',
        param: {
            ID: id,
            searchKey: 'Name',
            searchValue: ''
        }
    };
    const communityOutAccessRequestData: RequestData = {
        url: 'v3/web/community/access/getPeopleNotInAccess',
        param: {
            ID: id,
            searchKey: 'Name',
            searchValue: ''
        }
    };

    const officeInAccessRequestData: RequestData = {
        url: 'v3/web/office/access/getInAccessOfficeUser',
        param: {
            ID: id,
            searchKey: 'Name',
            searchValue: ''
        }
    };

    const officeOutAccessRequestData: RequestData = {
        url: 'v3/web/office/access/getOutAccessOfficeUser',
        param: {
            ID: id,
            searchKey: 'Name',
            searchValue: ''
        }
    };

    const communitySearchKey = [{
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Name'
    }, {
        label: WordList.RDeviceSearchLabelRoomName,
        name: 'RoomName'
    }];

    const officeSearchKey = [{
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Name'
    }, {
        label: WordList.ProperAllTextDepartment,
        name: 'Department'
    }];

    const accessData: ProjectAccessData = {
        community: {
            headers: communityHeaders,
            InAccessRequestData: communityInAccessRequestData,
            OutAccessRequestData: communityOutAccessRequestData,
            searchKeyList: communitySearchKey
        },
        office: {
            headers: officeHeaders,
            InAccessRequestData: officeInAccessRequestData,
            OutAccessRequestData: officeOutAccessRequestData,
            searchKeyList: officeSearchKey
        }
    };
    return accessData;
};

export default null;
export {
    getProjectAccessData,
    SearchKey
};
