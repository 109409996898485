
import {
    defineComponent, ref, Ref,
    computed, watch
} from 'vue';
import router, { propertyBaseRouter, propertySubRouter } from '@/router';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import {
    list, ListActionItem, ListHeaderItem,
    RequestData
} from '@/components/common/list';
import { access, community } from '@/data';
import HttpRequest from '@/util/axios.config';
import { getProjectAccessData, SearchKey } from './info';

interface ResultData {
    Name: string;
    SchedulerType: string;
    StartDay: string;
    StopDay: string;
    StartTime: string;
    StopTime: string;
    DateFlag: string;
    Location: string;
    UnitID: string;
}

interface InfoData extends ResultData {
    SchedulerTypeLabel: string;
}

export default defineComponent({
    components: {
        propertyBreadCrumb,
        secondaryNav,
        list
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const projectType = community.ProjectType;
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        const searchKeyList: Ref<SearchKey> = ref([]);
        const InAccessRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const OutAccessRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const updateList = ref(0);
        // 监听getpcommunitydetail接口返回的身份区分办公、社区
        watch(() => projectType.value, () => {
            const accessData = getProjectAccessData(props.id)[projectType.value];
            headers.value = accessData.headers;
            searchKeyList.value = accessData.searchKeyList;
            InAccessRequestData.value = accessData.InAccessRequestData;
            OutAccessRequestData.value = accessData.OutAccessRequestData;
            updateList.value += 1;
        }, {
            immediate: true
        });
        const breadCrumbHeaders = [
            {
                label: WordList.ProperAllTextAccessGroup,
                path: `/${propertyBaseRouter}/${propertySubRouter.access}`
            },
            {
                label: WordList.ProperAllTextView
            }
        ];

        const secNavs = [{
            label: WordList.ProperAllTextAddPeople,
            key: 'add'
        }, {
            label: WordList.ProperAllTextRemovePeople,
            key: 'remove'
        }];
        const secActive = ref('add');
        const chooseSecNav = (key: string) => {
            secActive.value = key;
        };

        const info: Ref<InfoData> = ref({
            Name: '',
            SchedulerType: '',
            StartDay: '',
            StopDay: '',
            StartTime: '',
            StopTime: '',
            DateFlag: '',
            Location: '',
            SchedulerTypeLabel: '',
            UnitID: ''
        });

        access.getPropertyAccessInfo(props.id, (data: ResultData) => {
            info.value = { ...data, SchedulerTypeLabel: '' };
            if (info.value.SchedulerType === '2') {
                const week = info.value.DateFlag.split(';');
                week.forEach((item, index) => {
                    if (item !== '') {
                        week[index] = access.weekList[Number(item)];
                    }
                });
                info.value.SchedulerTypeLabel = `${access.repeatLabels[Number(info.value.SchedulerType)]} ${week.join(';')}`;
            } else {
                info.value.SchedulerTypeLabel = access.repeatLabels[Number(info.value.SchedulerType)];
            }
        });

        const edit = () => {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.setAccess}?id=${props.id}`);
        };

        let Users: object[] = [];
        const multipleSelect = (checkVal: string[], selectItem: object[]) => {
            Users = selectItem;
        };

        const setPerson = () => {
            if (Users.length === 0) {
                return;
            }
            const url = secActive.value === 'add'
                ? `v3/web/${community.ProjectType.value}/access/addPeopleAccess`
                : `v3/web/${community.ProjectType.value}/access/delPeopleAccess`;
            HttpRequest.post(url, {
                ID: props.id,
                User: Users
            }, () => {
                updateList.value += 1;
            });
        };

        const addAction: ListActionItem[] = [
            {
                type: 'add',
                event(data: object) {
                    HttpRequest.post(`v3/web/${community.ProjectType.value}/access/addPeopleAccess`, {
                        ID: props.id,
                        User: [data]
                    }, () => {
                        updateList.value += 1;
                    });
                },
                class: 'modify-icon el-icon-my-add',
                title: WordList.FormAdd
            }
        ];
        const removeAction: ListActionItem[] = [
            {
                type: 'remove',
                event(data: object) {
                    HttpRequest.post(`v3/web/${community.ProjectType.value}/access/delPeopleAccess`, {
                        ID: props.id,
                        User: [data]
                    }, () => {
                        updateList.value += 1;
                    });
                },
                class: 'delete-icon el-icon-my-remove',
                title: WordList.TabelConfigInHtmlDelete
            }
        ];

        const OutAccessSearchValue = computed({
            get: () => (projectType.value === 'office' ? OutAccessRequestData.value.param.searchValue : OutAccessRequestData.value.param.searchValue),
            set: (val) => {
                if (projectType.value === 'office') {
                    OutAccessRequestData.value.param.searchValue = val;
                } else {
                    OutAccessRequestData.value.param.searchValue = val;
                }
            }
        });
        const OutAccessSearchKey = computed({
            get: () => (projectType.value === 'office' ? OutAccessRequestData.value.param.searchKey : OutAccessRequestData.value.param.searchKey),
            set: (val) => {
                if (projectType.value === 'office') {
                    OutAccessRequestData.value.param.searchKey = val;
                } else {
                    OutAccessRequestData.value.param.searchKey = val;
                }
            }
        });
        const InAccessSearchValue = computed({
            get: () => (projectType.value === 'office' ? InAccessRequestData.value.param.searchValue : InAccessRequestData.value.param.searchValue),
            set: (val) => {
                if (projectType.value === 'office') {
                    InAccessRequestData.value.param.searchValue = val;
                } else {
                    InAccessRequestData.value.param.searchValue = val;
                }
            }
        });
        const InAccessSearchKey = computed({
            get: () => (projectType.value === 'office' ? InAccessRequestData.value.param.searchKey : InAccessRequestData.value.param.searchKey),
            set: (val) => {
                if (projectType.value === 'office') {
                    InAccessRequestData.value.param.searchKey = val;
                } else {
                    InAccessRequestData.value.param.searchKey = val;
                }
            }
        });

        return {
            headers,
            breadCrumbHeaders,
            info,
            edit,
            secNavs,
            secActive,
            chooseSecNav,
            updateList,
            addAction,
            removeAction,
            multipleSelect,
            setPerson,
            InAccessRequestData,
            OutAccessRequestData,
            searchKeyList,
            projectType,
            OutAccessSearchValue,
            OutAccessSearchKey,
            InAccessSearchValue,
            InAccessSearchKey
        };
    }
});
